import React, { useState } from "react";
import {
  Box,
  Button,
  Input,
  FormControl,
  FormLabel,
  FormHelperText,
  HStack,
  useToast,
  FormErrorMessage,
  Text,
} from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";
import Sidebar from "./Sidebar";
import apiClient from "./utils/Api";
import { IconButton } from "@chakra-ui/react";
import { BsArrowLeft } from "react-icons/bs";

// Expresión regular para validar nombre y apellido
const VALID_NAME_REGEX = /^[A-Za-zÁÉÍÓÚáéíóúÑñ  ]+$/;

type Errors = {
  nombre?: string;
  apellido?: string;
  usuario?: string;
  correo?: string;
  password?: string;
};



type CreateUserFormProps = {
  user: {
    username: string;
    tipo: string;
  };
};

const CreateUserForm: React.FC<CreateUserFormProps> = ({ user })  => {
  const [nombre, setNombre] = useState("");
  const [apellido, setApellido] = useState("");
  const [usuario, setUsuario] = useState("");
  const [correo, setCorreo] = useState("");
  const [password, setPassword] = useState("");
  const [errors, setErrors] = useState<Errors>({});
  const [emailError, setEmailError] = useState("");
  const navigate = useNavigate();
  const toast = useToast();

  const handleSubmit = async (event: React.FormEvent) => {
    event.preventDefault();

    const tipo = "U";
    const estado = "A";

    try {
      const response = await apiClient.post("/usuarios/users", {
        nombre,
        apellido,
        usuario,
        correo,
        password,
        tipo,
        estado,
      });

      if (response.status === 201) {
        toast({
          title: "Usuario creado.",
          description: "El usuario ha sido agregado exitosamente.",
          status: "success",
          duration: 4000,
          isClosable: true,
          position: "top-right",
        });

        navigate("/users");
      }
    } catch (error: any) {
      if (error.response && error.response.status === 409) {
        setEmailError("El correo ya existe. Por favor, ingresa otro correo.");
      } else if (error.response && error.response.status === 400) {
        setErrors(error.response.data);
      } else {
        console.error("Error creating user:", error);
        toast({
          title: "Error.",
          description: "Hubo un problema al crear el usuario.",
          status: "error",
          duration: 5000,
          isClosable: true,
          position: "top-right",
        });
      }
    }
  };

  // Validar campos en tiempo real
  const validateField = (value: string, field: string) => {
    if (!VALID_NAME_REGEX.test(value)) {
      setErrors(prev => ({ ...prev, [field]: "El campo solo puede contener letras y tildes." }));
    } else {
      setErrors(prev => ({ ...prev, [field]: "" }));
    }
  };

  return (
    <Sidebar userName={user.username} tipo={user.tipo === "A" ? "Administrador" : "Contador"} >
      <Box display="flex" alignItems="center" mt="1%" mb="2%">
        <IconButton
          aria-label="Ir al panel de usuarios"
          icon={<BsArrowLeft />}
          ml="4"
          variant="ghost"
          onClick={() => navigate('/users')} 
          colorScheme="black"
        />
        <Text fontWeight="medium" fontFamily="DM Sans" ml="1">
          Ingresar Información
        </Text>
      </Box>
      <Box width="100%" maxWidth="900px" mx="auto" mt="10">
        <form onSubmit={handleSubmit}>
          <HStack spacing="4" mb="4">
            <FormControl id="nombre" isInvalid={!!errors.nombre}>
              <FormLabel fontWeight="regular">Nombre</FormLabel>
              <Input
                value={nombre}
                onChange={(e) => {
                  const value = e.target.value;
                  setNombre(value);
                  validateField(value, 'nombre');
                }}
                placeholder="Nombre"
                borderRadius="12px"
                padding="16px"
                color="#18181B"
              />
              <FormErrorMessage>{errors.nombre}</FormErrorMessage>
            </FormControl>

            <FormControl id="apellido" isInvalid={!!errors.apellido}>
              <FormLabel fontWeight="regular">Apellido</FormLabel>
              <Input
                value={apellido}
                onChange={(e) => {
                  const value = e.target.value;
                  setApellido(value);
                  validateField(value, 'apellido');
                }}
                placeholder="Apellido"
                borderRadius="12px"
                padding="16px"
                color="#18181B"
              />
              <FormErrorMessage>{errors.apellido}</FormErrorMessage>
            </FormControl>
          </HStack>

          <HStack spacing="4" mb="4">
            <FormControl id="usuario" isInvalid={!!errors.usuario}>
              <FormLabel fontWeight="regular">Usuario</FormLabel>
              <Input
                value={usuario}
                onChange={(e) => setUsuario(e.target.value)}
                placeholder="Usuario"
                borderRadius="12px"
                padding="16px"
                color="#18181B"
              />
              <FormErrorMessage>{errors.usuario}</FormErrorMessage>
            </FormControl>

            <FormControl id="correo" isInvalid={!!(emailError || errors.correo)}>
              <FormLabel fontWeight="regular">Correo</FormLabel>
              <Input
                type="email"
                value={correo}
                onChange={(e) => {
                  setCorreo(e.target.value);
                  setEmailError("");
                }}
                placeholder="Correo"
                borderRadius="12px"
                padding="16px"
                color="#18181B"
              />
              <FormErrorMessage>{emailError || errors.correo}</FormErrorMessage>
            </FormControl>
          </HStack>

          <HStack spacing="4" mb="4">
            <FormControl id="password" isInvalid={!!errors.password}>
              <FormLabel fontWeight="regular">Contraseña</FormLabel>
              <Input
                type="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                placeholder="Contraseña"
                borderRadius="12px"
                padding="16px"
                color="#18181B"
              />
              <FormHelperText>Debe contener al menos 8 caracteres.</FormHelperText>
              <FormErrorMessage>{errors.password}</FormErrorMessage>
            </FormControl>
          </HStack>

          <Button
            type="submit"
            bg="purple.600"
            colorScheme="purple"
            width="100%"
            maxWidth="150px"
            borderRadius="10px"
            fontWeight="regular"
            height="35px"
            display="block"
          >
            Crear
          </Button>
        </form>
      </Box>
    </Sidebar>
  );
};

export default CreateUserForm;
