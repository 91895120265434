import React, { useEffect, useState } from "react";
import { Box, Text, Flex, Spinner } from "@chakra-ui/react";
import Sidebar from "./Sidebar";
import apiClient from "./utils/Api";

const Dashboard = ({ user }) => {
  const [totalDTE, setTotalDTE] = useState(0);
  const [processedDTE, setProcessedDTE] = useState(0);
  const [unprocessedDTE, setUnprocessedDTE] = useState(0);
  const [loading, setLoading] = useState(true);
  const [currentDate, setCurrentDate] = useState("");
  const nitCompany = localStorage.getItem("nitCompany");

  useEffect(() => {
    const fetchDTEData = async () => {
      try {
        const response = await apiClient.get(`facturas/dte/summary?nitReceptor=${nitCompany}`);
        const data = response.data;

        setTotalDTE(data.totalDTE);
        setProcessedDTE(data.processedDTE);
        setUnprocessedDTE(data.unprocessedDTE);
      } catch (error) {
        console.error("Error al obtener los datos del DTE:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchDTEData();

    const updateDate = () => {
      const today = new Date();
      const options: Intl.DateTimeFormatOptions = {
        weekday: 'long',  
        day: 'numeric',   
        month: 'long',    
        year: 'numeric'   
      };
      const formattedDate = today.toLocaleDateString('es-ES', options);
      setCurrentDate(formattedDate);
    };

    updateDate();
  }, []);

  if (loading) {
    return (
      <Flex align="center" justify="center" height="100vh">
        <Spinner size="xl" />
      </Flex>
    );
  }

  return (
    <Sidebar userName={user.username} tipo={user.tipo === "A" ? "Administrador" : "Contador"} >
      <Box width="100%" mx="auto" mt="5" ml="5">
        <Text fontSize="2xl" fontWeight="bold" mb="4">
          Fecha
        </Text>
        <Text fontSize="lg" mb="6">
          {currentDate}
        </Text>

        <Flex direction="column" align="center">
          <Box
            bg="#4763E4"
            color="white"
            fontWeight="bold"
            fontSize="3xl"
            p="10"
            borderRadius="md"
            mb="6"
          >
            {totalDTE} DTE
          </Box>

          <Flex justify="space-between" width="60%">
            <Box
              bg="gray.100"
              color="gray.800"
              fontWeight="bold"
              fontSize="xl"
              p="6"
              borderRadius="md"
            >
              {processedDTE} PROCESADAS
            </Box>

            <Box
              bg="gray.100"
              color="gray.800"
              fontWeight="bold"
              fontSize="xl"
              p="6"
              borderRadius="md"
            >
              {unprocessedDTE} SIN PROCESAR
            </Box>
          </Flex>
        </Flex>
      </Box>
    </Sidebar>
  );
};

export default Dashboard;
