import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Box, Flex, Image, FormControl, FormLabel, Input, Button, Text, InputGroup, InputRightElement, IconButton, Select
} from '@chakra-ui/react';
import { MdOutlineRemoveRedEye } from "react-icons/md";
import { LuEyeOff } from "react-icons/lu";
import background from '../static/img/background-login.svg';
import logo from '../static/img/clobi-4.svg';
import footerImage from '../static/img/clobi-3.svg';
import apiClient from './utils/Api';
import CustomCheckbox from './utils/CustomCheck';

function Login({ onLoginSuccess }) {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [tipo, setTipoUsuario] = useState('');
  const [selectedCompany, setSelectedCompany] = useState('');
  const [companies, setCompanies] = useState([]);
  const [showPassword, setShowPassword] = useState(false);
  const [rememberMe, setRememberMe] = useState(false);
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const storedRememberMe = localStorage.getItem('rememberMe') === 'true';
    const storedUser = JSON.parse(
      (storedRememberMe ? localStorage.getItem('user') : sessionStorage.getItem('user')) || 'null'
    );
    if (storedUser) {
      onLoginSuccess(storedUser);
      navigate('/dashboard');
    }
  }, [navigate, onLoginSuccess]);

  useEffect(() => {
    if (username.trim()) {
      setLoading(true);
      const timeoutId = setTimeout(async () => {
        try {
          localStorage.setItem('user', username);
          const response = await apiClient.get(`empresas/users/${username}`);
          if (response.status === 200) {
            const { tipo_usuario, empresas } = response.data;
  
            // Establecer el tipo de usuario en el estado
            setError('');
            setTipoUsuario(tipo_usuario);
  
            if (empresas.length > 0) {
              setCompanies(empresas);
              setSelectedCompany(empresas[0].id);
            } else {
              setError('Usuario no encontrado o sin empresas activas');
              setCompanies([]);
              setSelectedCompany('');
            }
          } else {
            setError('Error al buscar las empresas');
            setCompanies([]);
            setSelectedCompany('');
          }
        } catch (error) {
          setError('Usuario no encontrado');
          setCompanies([]);
          setSelectedCompany('');
        } finally {
          setLoading(false);
        }
      }, 500);
  
      return () => clearTimeout(timeoutId);
    } else {
      setCompanies([]);
      setSelectedCompany('');
    }
  }, [username]);


const handleSubmit = async (e) => {
    e.preventDefault();
    setError('');
  
    try {
      let company_id = '';
  
      // Si el tipo de usuario es 'U', se requiere seleccionar una empresa
      if (tipo === 'U') {
        if (typeof selectedCompany === 'string' && selectedCompany.includes('-')) {
          const [selectedCompanyId, selectedCompanyNit] = selectedCompany.split('-');
          company_id = selectedCompanyId;
        } else {
          // Manejo de error si el formato de selectedCompany no es correcto
          setError('La compañía seleccionada no es válida');
          return;
        }
      } else if (tipo === 'A' && selectedCompany) {
        // Si el tipo de usuario es 'A' y se ha seleccionado una empresa
        if (typeof selectedCompany === 'string' && selectedCompany.includes('-')) {
          const [selectedCompanyId] = selectedCompany.split('-');
          company_id = selectedCompanyId;
        }
      }
  
      // Realizar la solicitud al backend
      const response = await apiClient.post('/login', {
        username,
        password,
        company_id: company_id,  // Enviar company_id si es necesario
      });
  
      if (response.status === 200 && response.data.user) {
        const user = response.data.user;
        const company = response.data.company;
  
        onLoginSuccess(user);
  
        if (user.tipo === 'U') {
          // Encontrar la empresa usando el NIT
          const selectedCompanyData = companies.find(company => company.nit === selectedCompany.split('-')[1]);
  
          if (selectedCompanyData) {
            // Guardar los datos de la empresa en localStorage
            localStorage.setItem('nitCompany', selectedCompanyData.nit);
            localStorage.setItem('nameCompany', selectedCompanyData.nombre);
          }
        } else if (user.tipo === 'A') {
          // Guardar los datos de la empresa obtenidos del backend en localStorage
          if (company) {
            localStorage.setItem('nitCompany', company.nit);
            localStorage.setItem('nameCompany', company.nombre);
          }
        }
      }
  
      // Manejo del "rememberMe"
      if (rememberMe) {
        localStorage.setItem('user', JSON.stringify(response.data.user));
        localStorage.setItem('rememberMe', 'true');
      } else {
        sessionStorage.setItem('user', JSON.stringify(response.data.user));
        localStorage.setItem('rememberMe', 'false');
      }
  
      // Redirigir al dashboard
      navigate('/dashboard');
  
    } catch (error) {
      if (error.response && error.response.data) {
        setError(error.response.data.message);
      } else if (error.message) {
        setError(error.message);
      } else {
        setError('Error de conexión');
      }
    }
  };


  return (
   

    <Flex direction="column" minHeight="100vh" width="100%">
      <Flex
        flex="1"
        alignItems="center"
        justifyContent="center"
        flexDirection={{ base: "column", md: "row" }}
        position="relative"
      >
        <Box
          width={{ base: "100%", md: "40%", lg: "40%" }}
          height="100vh"
          position="relative"
          display={{ base: "none", md: "block" }}
        >
          <Image
            src={background}
            alt="Background Image"
            objectFit="cover"
            height="100%"
            width="100%" />
          <Box
            position="absolute"
            top="50%"
            left="50%"
            transform="translate(-50%, -50%)"
            width="45%"
          >
            <Image src={logo} alt="Logo Image" width="100%" />
          </Box>
          <Box
            position="absolute"
            bottom="2%"
            left="20%"
            transform="translate(-50%, 0)"
            width="30%"
          >
            <Image src={footerImage} alt="Footer Image" width="100%" />
          </Box>
        </Box>
        <Box
          width={{ base: "100%", md: "60%", lg: "70%" }}
          p={8}
          display="flex"
          flexDirection="column"
          alignItems="center"
          justifyContent="center"
          position="relative"
        >
          <Box
            as="form"
            onSubmit={handleSubmit}
            width={{ base: "90%", sm: "80%", md: "50%" }}
            display="flex"
            flexDirection="column"
            alignItems="center"
          >
            <Text mb={6} fontSize="md" color="purple.600" fontWeight="bold" textAlign="left" >
              Sistema de recepción de facturas
            </Text>
            {error && (
              <Text mb={4} color="red.500">
                {error}
              </Text>
            )}
            <FormControl id="name" mb={4} width="100%">
              <FormLabel color="purple.500" fontWeight="medium">Nombre</FormLabel>
              <Input
                borderRadius="xl"
                type="text"
                placeholder="Nombre"
                color="gray.50"
                borderColor="gray.50"
                _placeholder={{ color: "gray.300" }}
                _focus={{ borderColor: "purple.600", boxShadow: "0 0 0 1px purple.600" }}
                value={username}
                onChange={(e) => setUsername(e.target.value)} />
            </FormControl>

            <FormControl id="company" mb={4} width="100%">
              <FormLabel color="purple.500" fontWeight="medium">Empresa</FormLabel>
              <Select
                borderRadius="xl"
                color="gray.50"
                placeholder={loading ? 'Cargando empresas...' : 'Selecciona una empresa'}
                borderColor="gray.50"
                _focus={{ borderColor: "purple.600", boxShadow: "0 0 0 1px purple.600" }}
                value={selectedCompany || ''} // Usar el string combinado
                onChange={(e) => setSelectedCompany(e.target.value)} // Guardar el string combinado
                isDisabled={loading || companies.length === 0}  
              >
                {companies.map(company => (
                  <option key={company.id} value={`${company.id}-${company.nit}`}>
                    {company.nombre}
                  </option>
                ))}
              </Select>
            </FormControl>

            <FormControl id="password" mb={4} width="100%">
              <FormLabel color="purple.500" fontWeight="medium">Contraseña</FormLabel>
              <InputGroup>
                <Input
                  borderRadius="xl"
                  type={showPassword ? 'text' : 'password'} 
                  placeholder="Min. 8 caracteres"
                  color="gray.50"
                  borderColor="gray.50"
                  _placeholder={{ color: "gray.300" }}
                  _focus={{ borderColor: "purple.600", boxShadow: "0 0 0 1px purple.600" }}
                  value={password}
                  onChange={(e) => setPassword(e.target.value)} />
                <InputRightElement>
                  <IconButton
                    variant="link"
                    color="gray.50"
                    aria-label={showPassword ? 'Ocultar contraseña' : 'Mostrar contraseña'}
                    icon={showPassword ? <LuEyeOff /> : <MdOutlineRemoveRedEye />}
                    onClick={() => setShowPassword(!showPassword)}
                  />
                </InputRightElement>
              </InputGroup>
            </FormControl>

            <Box
              display="flex"
              flexDirection={{ base: "column", sm: "row" }}
              justifyContent="space-between"
              width="100%"
              mb={4}
              textAlign={{ base: "center", sm: "left" }}
            >
              <CustomCheckbox
                isChecked={rememberMe}
                onChange={(e) => setRememberMe(e.target.checked)} />
            </Box>
            <Button
              type="submit"
              width="full"
              height='50px'
              mt={4}
              bg="purple.400"
              color="white"
              borderWidth="1px"
              borderColor="purple.400"
              borderRadius="12px"
              fontWeight="bold"
              _hover={{
                bg: "purple.500",
                borderColor: "purple.500",
                boxShadow: "md",
              }}
            >
              Iniciar sesión
            </Button>
            
          </Box>
        </Box>
      </Flex>
    </Flex>
  );
}

export default Login;
