import React, { ReactNode, useEffect, useState } from "react";
import apiClient from "./utils/Api";
import {
  IconButton,
  Avatar,
  Box,
  Flex,
  HStack,
  VStack,
  Icon,
  Image,
  Text,
  Drawer,
  DrawerContent,
  useDisclosure,
  BoxProps,
  FlexProps,
  Menu,
  MenuButton,
  MenuDivider,
  MenuItem,
  MenuList,
  Spacer,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
  FormControl,
  FormLabel,
  Select,
} from "@chakra-ui/react";
import {
  FiHome,
  FiBarChart2,
  FiGrid,
  FiUser,
  FiMenu,
  FiChevronDown,
} from "react-icons/fi";
import { IconType } from "react-icons";
import {
  Link as RouterLink,
  useNavigate,
  useLocation,
  Link,
} from "react-router-dom";
import footer from "../static/img/clobi-2.svg";

interface LinkItemProps {
  name: string;
  icon: IconType;
  path: string;
}

interface NavItemProps extends FlexProps {
  icon: IconType;
  children: ReactNode;
  path: string;
}

const getPageName = () => {
  const { pathname } = window.location;

  if (/^\/access-companies\/\d+$/.test(pathname)) {
    return "Acceso Usuario";
  }

  switch (pathname) {
    case "/dashboard":
      return "Inicio";
    case "/invoices":
      return "Facturas";
    case "/settings":
      return "Configuración";
    case "/users":
      return "Usuarios";
    case "/create-user":
      return "Crear Usuario";
    case "/invoicesNP":
      return "Facturas No Procesadas";
    default:
      return "Editar Usuario";
  }
};

interface MobileProps extends FlexProps {
  onOpen: () => void;
  onToggleSidebar: () => void;
  userName: string;
  tipo: string;
  isSidebarOpen: boolean;
  onOpenChangeCompanyModal: () => void;
}

interface SidebarProps extends BoxProps {
  onClose: () => void;
  userName: string;
  tipo: string;
  // onOpenChangeCompanyModal: () => void;
}

interface SidebarWithHeaderProps {
  children: ReactNode;
  userName: string;
  tipo: string;
}

// const { isOpen, onOpen, onClose } = useDisclosure();
// const [isSidebarOpen, setSidebarOpen] = useState(false); // Mueve estos hooks al interior del componente
// const [isModalOpen, setModalOpen] = useState(false);     // Mueve estos hooks al interior del componente
// const [selectedCompany, setSelectedCompany] = useState('');  // Mueve estos hooks al interior del componente
// const companies = [/* Aquí irían las empresas que debes pasar como prop */];

const LinkItemsA: Array<LinkItemProps> = [
  { name: "Inicio", icon: FiHome, path: "/dashboard" },
  { name: "Facturas", icon: FiBarChart2, path: "/invoices" },
  { name: "Facturas No Procesadas", icon: FiBarChart2, path: "/invoicesNP" },
  { name: "Configuración", icon: FiGrid, path: "/settings" },
  { name: "Usuarios", icon: FiUser, path: "/users" },
];
const LinkItemsU: Array<LinkItemProps> = [
  { name: "Inicio", icon: FiHome, path: "/dashboard" },
  { name: "Facturas", icon: FiBarChart2, path: "/invoices" },
  { name: "Facturas No procesadas", icon: FiBarChart2, path: "/invoicesNP" },
  { name: "Configuración", icon: FiGrid, path: "/settings" },
];

const SidebarContent = ({ onClose, userName, tipo, ...rest }: SidebarProps) => {
  const nameCompany = localStorage.getItem("nameCompany");

  return (
    <Box
      transition="3s ease"
      bg="#FFFFFF"
      borderRight="1px solid #E2E8F0"
      w={{ base: "full", md: 60 }}
      pos="fixed"
      h="full"
      display="flex"
      flexDirection="column"
      justifyContent="space-between"
      overflowY="auto"
      {...rest}
    >
      <Box>
        <Flex alignItems="center" mt="20px">
          <Menu>
            <MenuButton
              py={2}
              transition="all 0.3s"
              _focus={{ boxShadow: "none" }}
            >
              <HStack mb="5">
                <VStack
                  display={{ base: "none", md: "flex" }}
                  alignItems="flex-start"
                  spacing="1px"
                >
                  <Box
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                    height="100%"
                    textAlign="center"
                  >
                    <Text
                      display="inline-block"
                      fontSize="14px"
                      fontFamily="DM Sans"
                      color="#A3AED0"
                    >
                      {nameCompany}
                    </Text>
                  </Box>
                </VStack>
              </HStack>
            </MenuButton>
          </Menu>
        </Flex>
        {(tipo == "Administrador" ? LinkItemsA : LinkItemsU).map((link) => (
          <NavItem key={link.name} icon={link.icon} path={link.path}>
            {link.name}
          </NavItem>
        ))}
      </Box>
      <Spacer />
      <Flex
        justifyContent="center"
        alignItems="center"
        mt={tipo === "Administrador" ? "80%" : "100%"}
      >
        <Image width="180px" src={footer} alt="Footer" />
      </Flex>
    </Box>
  );
};

const NavItem = ({ icon, children, path, ...rest }: NavItemProps) => (
  <RouterLink to={path} style={{ textDecoration: "none" }}>
    <Flex
      align="center"
      p="4"
      mx="4"
      borderRadius="lg"
      role="group"
      cursor="pointer"
      color="#A3AED0"
      _hover={{
        bg: "#E0E5F2",
        color: "#9880FF",
      }}
      {...rest}
    >
      {icon && (
        <Icon
          mr="4"
          fontSize="16"
          color="#A3AED0"
          _groupHover={{
            color: "#9880FF",
          }}
          as={icon}
        />
      )}
      {children}
    </Flex>
  </RouterLink>
);

const MobileNav = ({
  onOpen,
  onToggleSidebar,
  userName,
  tipo,
  isSidebarOpen,
  onOpenChangeCompanyModal, // Agrega la función de abrir el modal
  ...rest
}: MobileProps) => {
  const navigate = useNavigate();
  const currentLocation = useLocation();
  const nameCompany = localStorage.getItem("nameCompany");

  const handleLogout = () => {
    localStorage.removeItem("user");
    localStorage.removeItem("nitCompany");
    localStorage.removeItem("nameCompany");
    localStorage.removeItem("rememberMe");
    sessionStorage.removeItem("user");
    navigate("/login");
  };

  const handleSettings = () => {
    navigate("/settings");
  };

  return (
    <Flex
      px={{ base: 4, md: 4 }}
      height="20"
      alignItems="center"
      justifyContent={{ base: "space-between", md: "flex-end" }}
      ml={{ base: 0, md: isSidebarOpen ? 60 : 0 }}
      top={0}
      left={0}
      mb="-5"
      width="100%"
      zIndex={1000}
      {...rest}
    >
      <IconButton
        display={{ base: "flex", md: "none" }}
        onClick={onOpen}
        variant="outline"
        aria-label="open menu"
        icon={<FiMenu />}
      />
      <IconButton
        display={{ base: "none", md: "flex" }}
        onClick={onToggleSidebar}
        variant="outline"
        border="none"
        aria-label="toggle sidebar"
        icon={isSidebarOpen ? <FiMenu /> : <FiMenu />}
      />
      <Text
        display={{ base: "flex", md: "none" }}
        fontSize="xl"
        fontFamily="DM Sans"
        fontWeight="bold"
      >
        {nameCompany}
      </Text>
      <HStack spacing={{ base: "0", md: "6" }} width="100%">
        <Text
          display={{ base: "none", md: "flex" }}
          fontSize="lg"
          fontFamily="DM Sans"
          fontWeight="bold"
          color="black"
          mr="auto"
          ml="4"
        >
          {currentLocation.pathname.includes("/dashboard") ||
          currentLocation.pathname.includes("/invoices") ||
          currentLocation.pathname.includes("/invoicesNP") ? (
            <Link
              to="/dashboard"
              style={{ textDecoration: "none", color: "black" }}
            >
              {nameCompany}
            </Link>
          ) : (
            "Configuración de cuenta"
          )}
          <Text ml="2" fontWeight="normal" fontFamily="DM Sans">
            &gt;
          </Text>
          <Text as="span" fontWeight="normal" fontFamily="DM Sans" ml="2">
            {getPageName()}
          </Text>
        </Text>
        <Flex alignItems="center">
          <Menu>
            <MenuButton
              py={2}
              transition="all 0.5s"
              _focus={{ boxShadow: "none" }}
            >
              <HStack>
                <Avatar
                  size="sm"
                  src="https://scontent.xx.fbcdn.net/v/t1.30497-1/115870214_694925034696967_1870022665148339563_n.jpg?stp=dst-jpg_s200x200&_nc_cat=1&ccb=1-7&_nc_sid=7565cd&_nc_ohc=PNzdR4JoG0cQ7kNvgE4fdV4&_nc_ad=z-m&_nc_cid=0&_nc_ht=scontent.xx&oh=00_AYD_J09f4CQ8Ub6_mvJEBVUKWscohEKVqnfEAKO3rAAa7A&oe=66FDA0A2"
                />
                <VStack
                  display={{ base: "none", md: "flex" }}
                  alignItems="flex-start"
                  spacing="1px"
                  ml="2"
                >
                  <Text fontSize="sm">{userName}</Text>
                  <Text fontSize="xs" color="gray.600">
                    {tipo}
                  </Text>
                </VStack>
                <Box display={{ base: "none", md: "block" }} ml="2">
                  <FiChevronDown />
                </Box>
              </HStack>
            </MenuButton>
            <MenuList>
              <MenuItem onClick={onOpenChangeCompanyModal}>
                Cambiar Empresa
              </MenuItem>{" "}
              {/* Cambia a la función de abrir el modal */}
              <MenuDivider />
              <MenuItem onClick={handleSettings}>Configuración</MenuItem>
              <MenuItem onClick={handleLogout}>Cerrar Sesión</MenuItem>
            </MenuList>
          </Menu>
        </Flex>
      </HStack>
    </Flex>
  );
};

const SidebarWithHeader: React.FC<SidebarWithHeaderProps> = ({
  children,
  userName,
  tipo,
}) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [isSidebarOpen, setSidebarOpen] = React.useState(false);
  const [isModalOpen, setModalOpen] = useState(false); // Agrega estado para el modal
  // const [selectedCompany, setSelectedCompany] = useState('');  // Agrega estado para la empresa seleccionada
  // const companies = [/* Aquí irían las empresas que debes pasar como prop */];
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [tipoUsuario, setTipoUsuario] = useState("");
  const [selectedCompany, setSelectedCompany] = useState("");
  const [companies, setCompanies] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const navigate = useNavigate();

  const toggleSidebar = () => {
    if (window.innerWidth < 768) {
      if (isOpen) {
        onClose();
      } else {
        onOpen();
      }
    } else {
      setSidebarOpen((prev) => !prev);
    }
  };

  useEffect(() => {
    if (window.innerWidth >= 768) {
      setSidebarOpen(false);
    }
    const storedUsername = localStorage.getItem("user");

    if (storedUsername && storedUsername.trim()) {
      // Si hay un nombre de usuario almacenado, usa este valor
      setUsername(storedUsername);

      setLoading(true);
      const timeoutId = setTimeout(async () => {
        try {
          // Utiliza el nombre recuperado de localStorage
          const response = await apiClient.get(
            `empresas/users/${storedUsername}`
          );
          if (response.status === 200) {
            const { tipo_usuario, empresas } = response.data;

            // Establecer el tipo de usuario en el estado
            setTipoUsuario(tipo_usuario);

            if (empresas.length > 0) {
              setCompanies(empresas);
              setSelectedCompany(empresas[0].id);
            } else {
              setError("Usuario no encontrado o sin empresas activas");
              setCompanies([]);
              setSelectedCompany("");
            }
          } else {
            setError("Error al buscar las empresas");
            setCompanies([]);
            setSelectedCompany("");
          }
        } catch (error) {
          setError("Usuario no encontrado");
          setCompanies([]);
          setSelectedCompany("");
        } finally {
          setLoading(false);
        }
      }, 500);

      return () => clearTimeout(timeoutId);
    } else {
      setCompanies([]);
      setSelectedCompany("");
    }
  }, [username]);
  // }, []);

  const handleChangeCompany = () => {
    const selectedCompanyData = companies.find(
      (company) => company.nit === selectedCompany.split("-")[1]
    );
    // Aquí puedes manejar el cambio de empresa
    localStorage.setItem("selectedCompany", selectedCompany);
    localStorage.setItem("nitCompany", selectedCompanyData.nit);
    localStorage.setItem("nameCompany", selectedCompanyData.nombre);
    // Redirigir al dashboard
    window.location.reload();
    setModalOpen(false);
  };

  return (
    <Box minH="100vh" bg="white" position="relative" overflowX="hidden">
      <SidebarContent
        onClose={onClose}
        userName={userName}
        tipo={tipo}
        display={{ base: "none", md: isSidebarOpen ? "block" : "none" }}
      />
      <Drawer
        autoFocus={false}
        isOpen={isOpen}
        placement="left"
        onClose={onClose}
        returnFocusOnClose={false}
      >
        <DrawerContent>
          <SidebarContent onClose={onClose} userName={userName} tipo={tipo} />
        </DrawerContent>
      </Drawer>
      <MobileNav
        onOpen={onOpen}
        onToggleSidebar={toggleSidebar}
        userName={userName}
        tipo={tipo}
        isSidebarOpen={isSidebarOpen}
        onOpenChangeCompanyModal={() => setModalOpen(true)} // Pasa la función para abrir el modal
      />
      <Box
        ml={{ base: 0, md: isSidebarOpen ? 60 : 0 }}
        transition="margin-left 0.3s ease"
        p="4"
        minWidth="0"
      >
        {children}
      </Box>
      <Modal isOpen={isModalOpen} onClose={() => setModalOpen(false)}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader textAlign="center">Cambiar Empresa</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <FormControl id="company" mb={4} width="100%">
              <Box maxH="200px" overflowY="auto">
                <Select
                  borderRadius="xl"
                  color="gray.50"
                  placeholder={
                    loading ? "Cargando empresas..." : "Selecciona una empresa"
                  }
                  borderColor="gray.50"
                  _focus={{
                    borderColor: "purple.600",
                    boxShadow: "0 0 0 1px purple.600",
                  }}
                  value={selectedCompany || ""}
                  onChange={(e) => setSelectedCompany(e.target.value)}
                  isDisabled={loading || companies.length === 0}
                >
                  {companies.map((company) => (
                    <option
                      key={company.id}
                      value={`${company.id}-${company.nit}`}
                    >
                      {company.nombre}
                    </option>
                  ))}
                </Select>
              </Box>
            </FormControl>
          </ModalBody>
          <ModalFooter>
            <Button
              ml="10px"
              bg="purple.600"
              colorScheme="purple"
              width="100%"
              maxWidth="180px"
              borderRadius="100px"
              fontWeight="regular"
              height="30px"
              display="block"
              onClick={handleChangeCompany}
            >
              Cambiar Empresa
            </Button>
            <Button
              ml="10px"
              bg="gray.150"
              colorScheme="gray.150"
              width="100%"
              maxWidth="180px"
              borderRadius="100px"
              fontWeight="regular"
              height="30px"
              color="gray.200"
              display="block"
              onClick={() => setModalOpen(false)}
            >
              Cancelar
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Box>
  );
};

export default SidebarWithHeader;
