import React, { useState, useEffect } from "react";
import {
  Box,
  Button,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Input,
  useToast,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  HStack,
  Text,
} from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";
import Sidebar from "./Sidebar";
import apiClient from "./utils/Api";

const Users = ({ user}) => {
  const userType = user.tipo === "A" ? "Administrador" : "Contador";
  const [users, setUsers] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [selectedUserId, setSelectedUserId] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const navigate = useNavigate();
  const toast = useToast();

  const fetchUsers = async (search = "", page = 1) => {
    try {
      const response = await apiClient.get("/usuarios/users", {
        params: { query: search, page: page },
      });
      setUsers(response.data.users);
      setPage(response.data.pagination.page);
      setTotalPages(
        Math.ceil(
          response.data.pagination.total / response.data.pagination.per_page
        )
      );
    } catch (error) {
      console.error("Error fetching users:", error);
    }
  };

  useEffect(() => {
    fetchUsers(searchQuery, page);
  }, [searchQuery, page]);

  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
    setPage(1); // Resetear la página cuando se realiza una nueva búsqueda
  };

  const handleCreateUser = () => {
    navigate("/create-user");
  };

  const handleEditUser = (userId) => {
    navigate(`/edit-user/${userId}`);
  };

  const handleToggleEstado = async (userId) => {
    try {
      await apiClient.patch(`/usuarios/users/${userId}/estado`);
      fetchUsers(searchQuery, page);

      toast({
        title: "Estado actualizado.",
        description: "El estado del usuario ha sido actualizado.",
        status: "success",
        duration: 5000,
        isClosable: true,
        position: "top-right",
      });
    } catch (error) {
      console.error("Error updating user estado:", error);
      toast({
        title: "Error.",
        description: "Hubo un problema al actualizar el estado del usuario.",
        status: "error",
        duration: 5000,
        isClosable: true,
        position: "top-right",
      });
    }
  };

  const handleDeleteUser = async () => {
    try {
      await apiClient.delete(`/usuarios/users/${selectedUserId}`);
      fetchUsers(searchQuery, page);
      setIsModalOpen(false);

      toast({
        title: "Usuario eliminado.",
        description: "El usuario ha sido eliminado con éxito.",
        status: "success",
        duration: 5000,
        isClosable: true,
        position: "top-right",
      });
    } catch (error) {
      console.error("Error deleting user:", error);
      toast({
        title: "Error.",
        description: "Hubo un problema al eliminar el usuario.",
        status: "error",
        duration: 5000,
        isClosable: true,
        position: "top-right",
      });
    }
  };

  const openDeleteConfirmation = (userId) => {
    setSelectedUserId(userId);
    setIsModalOpen(true);
  };

  const closeDeleteConfirmation = () => {
    setIsModalOpen(false);
    setSelectedUserId(null);
  };

  const handleAccessClick = (userId) => {
    navigate(`/access-companies/${userId}`, { state: { user: user } });
  };

  const handlePageChange = (newPage) => {
    setPage(newPage);
  };

  return (
    <Sidebar userName={user.username} tipo={userType} >
      <Box
        width="100%"
        display="flex"
        flexDirection="column"
        alignItems="center"
      >
        <Box
          bg="gray.100"
          width="100%"
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          p="5"
        >
          <Input
            placeholder="Buscar"
            value={searchQuery}
            onChange={handleSearchChange}
            width="250px"
            bg="white"
            border="none"
            height="30px"
            fontSize="15px"
            color="#8BA3CB"
          />
          <Button variant="link" color="purple.600" onClick={handleCreateUser}>
            <span>+ Crear nuevo usuario</span>
          </Button>
        </Box>

        <Table variant="simple" border="none" textAlign="center">
          <Thead>
            <Tr bg="#F5F7FB">
              <Th
                border="none"
                fontSize="15px"
                textAlign="left"
                verticalAlign="middle"
                textTransform="none"
              >
                Nombre
              </Th>
              <Th
                border="none"
                fontSize="15px"
                textAlign="center"
                verticalAlign="middle"
                textTransform="none"
              >
                Usuario
              </Th>
              <Th
                border="none"
                fontSize="15px"
                textAlign="center"
                verticalAlign="middle"
                textTransform="none"
              >
                Rol
              </Th>
              <Th
                border="none"
                fontSize="15px"
                textAlign="center"
                verticalAlign="middle"
                textTransform="none"
              >
                Estado
              </Th>
              <Th
                border="none"
                fontSize="15px"
                textAlign="center"
                verticalAlign="middle"
                textTransform="none"
              ></Th>
              <Th
                border="none"
                fontSize="15px"
                textAlign="center"
                verticalAlign="middle"
                textTransform="none"
              ></Th>
              <Th
                border="none"
                fontSize="15px"
                textAlign="center"
                verticalAlign="middle"
                textTransform="none"
              ></Th>
            </Tr>
          </Thead>
          <Tbody>
            {users.map((user) => (
              <Tr key={user.id}>
                <Td border="none" textAlign="left" verticalAlign="middle">
                  {`${user.nombre} ${user.apellido}`}
                </Td>
                <Td border="none" textAlign="center" verticalAlign="middle">
                  {user.usuario}
                </Td>
                <Td border="none" textAlign="center" verticalAlign="middle">
                  {user.tipo === "A" ? "Administrador" : "Contador"}
                </Td>
                <Td border="none" textAlign="center" verticalAlign="middle">
                  <Button
                    bg={user.estado === "A" ? "purple.600" : "#DDDDDD"}
                    color={user.estado === "A" ? "#fff" : "#888888"}
                    borderRadius="100px"
                    width="100px"
                    _hover={{ 
                      bg: "purple.600", 
                      color: "white" 
                    }} 
                    fontWeight="regular"
                    height="30px"
                    onClick={() => handleToggleEstado(user.id)}
                  >
                    {user.estado === "A" ? "Activo" : "Inactivo"}
                  </Button>
                </Td>
                <Td border="none" textAlign="center" verticalAlign="middle">
                  <Button
                    variant="outline"
                    colorScheme="purple"
                    fontWeight="regular"
                    borderRadius="100px"
                    height="30px"
                    onClick={() => handleEditUser(user.id)}
                  >
                    Editar
                  </Button>
                </Td>
                <Td border="none" textAlign="center" verticalAlign="middle">
                  <Button
                    variant="outline"
                    colorScheme="purple"
                    fontWeight="regular"
                    borderRadius="100px"
                    height="30px"
                    onClick={() => openDeleteConfirmation(user.id)}
                  >
                    Eliminar
                  </Button>
                </Td>
                <Td border="none" textAlign="left" verticalAlign="middle">
                  <Button
                    variant="outline"
                    colorScheme="purple"
                    fontWeight="regular"
                    borderRadius="100px"
                    height="30px"
                    onClick={() => handleAccessClick(user.id)} 
                  >
                    Accesos
                  </Button>
                </Td>
              </Tr>
            ))}
          </Tbody>
        </Table>

        <Box mt="20px"  width="100%">
          <HStack spacing="4" justify="flex-end">
            <Button
              onClick={() => handlePageChange(page > 1 ? page - 1 : page)}
              isDisabled={page === 1}
            >
              {"<"}
            </Button>

            {[...Array(totalPages)].map((_, i) => (
              <Button
                key={i + 1}
                onClick={() => handlePageChange(i + 1)}
                colorScheme={page === i + 1 ? "blue" : "gray"}
                variant={page === i + 1 ? "solid" : "outline"}
              >
                {i + 1}
              </Button>
            ))}

            <Button
              onClick={() =>
                handlePageChange(page < totalPages ? page + 1 : page)
              }
              isDisabled={page === totalPages}
            >
              {">"}
            </Button>
          </HStack>
        </Box>

        {/* Modal de confirmación */}
        <Modal isOpen={isModalOpen} onClose={closeDeleteConfirmation}>
          <ModalOverlay />
          <ModalContent>
            <ModalHeader>Confirmar Eliminación</ModalHeader>
            <ModalCloseButton />
            <ModalBody>
              ¿Estás seguro de que quieres eliminar este usuario?
            </ModalBody>
            <ModalFooter>
              <Button variant="ghost" onClick={closeDeleteConfirmation}>
                Cancelar
              </Button>
              <Button colorScheme="red" ml={3} onClick={handleDeleteUser}>
                Eliminar
              </Button>
            </ModalFooter>
          </ModalContent>
        </Modal>
      </Box>
    </Sidebar>
  );
};

export default Users;