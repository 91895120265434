import React, { useState, useEffect, useRef } from "react";
import {
  Box,
  Button,
  Input,
  FormControl,
  FormLabel,
  FormHelperText,
  HStack,
  useToast,
  FormErrorMessage,
  Text,
  AlertDialog,
  AlertDialogBody,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogContent,
  AlertDialogOverlay,
} from "@chakra-ui/react";
import { useNavigate, useParams } from "react-router-dom";
import Sidebar from "./Sidebar";
import apiClient from "./utils/Api";
import { IconButton } from "@chakra-ui/react";
import { BsArrowLeft } from "react-icons/bs";

// Expresión regular para validar nombre y apellido
const VALID_NAME_REGEX = /^[A-Za-zÁÉÍÓÚáéíóúÑñ ]+$/;

type Errors = {
  nombre?: string;
  apellido?: string;
  usuario?: string;
  correo?: string;
  password?: string;
};

const EditUserForm = ({ user }) => {
  const [nombre, setNombre] = useState("");
  const [apellido, setApellido] = useState("");
  const [usuario, setUsuario] = useState("");
  const [correo, setCorreo] = useState("");
  const [password, setPassword] = useState("");
  const [errors, setErrors] = useState<Errors>({});
  const [emailError, setEmailError] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const navigate = useNavigate();
  const toast = useToast();
  const { id } = useParams(); // Obtenemos el ID del usuario desde los parámetros de la URL

  const [isOpen, setIsOpen] = useState(false); // Estado para controlar la apertura del modal
  const onClose = () => setIsOpen(false); // Función para cerrar el modal
  const cancelRef = useRef(); // Referencia al botón de cancelar en el modal

  useEffect(() => {
    // Cargar los datos del usuario por ID
    const fetchUserData = async () => {
      try {
        const response = await apiClient.get(`/usuarios/users/${id}`);
        const user = response.data;
        setNombre(user.nombre);
        setApellido(user.apellido);
        setUsuario(user.usuario);
        setCorreo(user.correo);
        // No se carga la contraseña existente por razones de seguridad
      } catch (error) {
        console.error("Error fetching user data:", error);
        toast({
          title: "Error.",
          description: "No se pudo cargar la información del usuario.",
          status: "error",
          duration: 5000,
          isClosable: true,
          position: "top-right",
        });
      }
    };

    fetchUserData();
  }, [id, toast]);

  // Validar campos en tiempo real
  const validateField = (value: string, field: string) => {
    let newErrors = { ...errors };

    if (!value) {
      newErrors[field] = "Este campo es obligatorio.";
    } else if (field === "nombre" || field === "apellido") {
      if (!VALID_NAME_REGEX.test(value)) {
        newErrors[field] = "El campo solo puede contener letras y tildes.";
      } else {
        newErrors[field] = "";
      }
    } else {
      newErrors[field] = "";
    }

    setErrors(newErrors);
  };

  const handleSubmit = (event) => {
    event.preventDefault();

    if (!nombre) {
      setErrors((prev) => ({ ...prev, nombre: "Este campo es obligatorio." }));
      return;
    }

    if (!apellido) {
      setErrors((prev) => ({
        ...prev,
        apellido: "Este campo es obligatorio.",
      }));
      return;
    }

    if (!usuario) {
      setErrors((prev) => ({ ...prev, usuario: "Este campo es obligatorio." }));
      return;
    }

    if (!correo) {
      setErrors((prev) => ({ ...prev, correo: "Este campo es obligatorio." }));
      return;
    }

    if (password && password.length < 8) {
      setPasswordError("La contraseña debe tener al menos 8 caracteres.");
      return;
    }

    setIsOpen(true); 
  };

  const confirmSubmit = async () => {
    try {
      const response = await apiClient.put(`/usuarios/users/${id}`, {
        nombre,
        apellido,
        usuario,
        correo,
        password: password || undefined, 
      });

      if (response.status === 200) {
        toast({
          title: "Usuario actualizado.",
          description: "El usuario ha sido actualizado exitosamente.",
          status: "success",
          duration: 4000,
          isClosable: true,
          position: "top-right",
        });

        navigate("/users");
      }
    } catch (error) {
      if (error.response && error.response.status === 409) {
        setEmailError("El correo ya existe. Por favor, ingresa otro correo.");
      } else {
        console.error("Error updating user:", error);
        toast({
          title: "Error.",
          description: "Hubo un problema al actualizar el usuario.",
          status: "error",
          duration: 5000,
          isClosable: true,
          position: "top-right",
        });
      }
    } finally {
      onClose(); 
    }
  };

  return (
    <Sidebar
      userName={user.username}
      tipo={user.tipo === "A" ? "Admisnitrador" : "Contador"}
    >
      <Box display="flex" alignItems="center" mt="1%" mb="2%">
        <IconButton
          aria-label="Ir al panel de usuarios"
          icon={<BsArrowLeft />}
          ml="4"
          variant="ghost"
          onClick={() => navigate('/users')} 
          colorScheme="black"
        />
        <Text fontWeight="medium" fontFamily="DM Sans" ml="1">
          Editar Información
        </Text>
      </Box>
      <Box
        width="100%"
        maxWidth="900px"
        mx="auto" // Centra horizontalmente
        mt="10"
        display="flex"
        justifyContent="center" // Asegura que el contenido esté centrado
        flexDirection="column"
      >
        <form onSubmit={handleSubmit}>
          <HStack spacing="4" mb="4">
            <FormControl id="nombre" isInvalid={!!errors.nombre}>
              <FormLabel fontWeight="regular">Nombre</FormLabel>
              <Input
                value={nombre}
                onChange={(e) => {
                  const value = e.target.value;
                  setNombre(value);
                  validateField(value, "nombre");
                }}
                placeholder="Nombre"
                borderRadius="12px"
                padding="16px"
                color="#18181B"
              />
              <FormErrorMessage>{errors.nombre}</FormErrorMessage>
            </FormControl>

            <FormControl id="apellido" isInvalid={!!errors.apellido}>
              <FormLabel fontWeight="regular">Apellido</FormLabel>
              <Input
                value={apellido}
                onChange={(e) => {
                  const value = e.target.value;
                  setApellido(value);
                  validateField(value, "apellido");
                }}
                placeholder="Apellido"
                borderRadius="12px"
                padding="16px"
                color="#18181B"
              />
              <FormErrorMessage>{errors.apellido}</FormErrorMessage>
            </FormControl>
          </HStack>

          <HStack spacing="4" mb="4">
            <FormControl id="usuario" isInvalid={!!errors.usuario}>
              <FormLabel fontWeight="regular">Usuario</FormLabel>
              <Input
                value={usuario}
                onChange={(e) => setUsuario(e.target.value)}
                placeholder="Usuario"
                borderRadius="12px"
                margin-top="10px"
                padding="16px"
                color="#18181B"
              />
              <FormErrorMessage>{errors.usuario}</FormErrorMessage>
            </FormControl>

            <FormControl  id="correo" isInvalid={!!(emailError || errors.correo)}>
              <FormLabel fontWeight="regular">Correo</FormLabel>
              <Input
                type="email"
                value={correo}
                onChange={(e) => {
                  setCorreo(e.target.value);
                  setEmailError("");
                }}
                placeholder="Correo"
                borderRadius="12px"
                padding="16px"
                color="#18181B"
              />
              <FormErrorMessage>{emailError || errors.correo}</FormErrorMessage>
            </FormControl>
          </HStack>

          <HStack spacing="4" mb="4">
            <FormControl id="password" isInvalid={!!errors.password}>
              <FormLabel fontWeight="regular">Contraseña</FormLabel>
              <Input
                type="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                placeholder="Contraseña"
                borderRadius="12px"
                padding="16px"
                color="#18181B"
              />
              <FormHelperText>Debe contener al menos 8 caracteres.</FormHelperText>
              <FormErrorMessage>{errors.password}</FormErrorMessage>
            </FormControl>
          </HStack>

          <Button
            type="submit"
            bg="purple.600"
            colorScheme="purple"
            width="100%"
            maxWidth="150px"
            borderRadius="10px"
            fontWeight="regular"
            height="35px"
            display="block"
          >
            Actualizar
          </Button>
        </form>
      </Box>

      <AlertDialog
        isOpen={isOpen}
        leastDestructiveRef={cancelRef}
        onClose={onClose}
      >
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize="lg" fontWeight="bold">
              Confirmar Actualización
            </AlertDialogHeader>

            <AlertDialogBody>
              ¿Estás seguro de que deseas actualizar la información del usuario?
            </AlertDialogBody>

            <AlertDialogFooter>
              <Button 
               borderRadius="100px"
               width="100px"
               fontWeight="regular"
               height="30px"
               color="gray.200"
               bg="gray.150"
               _hover={{ 
                 bg: "gray.150", 
                 color: "gray.200" 
               }} 
              ref={cancelRef} onClick={onClose}>
                Cancelar
              </Button>
              <Button 
              borderRadius="100px"
              width="100px"
              fontWeight="medium"
              height="30px"
              color="white"
              bg="purple.600"
              _hover={{ 
                bg: "purple.600", 
                color: "white" 
              }} 
              maxWidth="300px"
              onClick={confirmSubmit} ml={3}>
                Confirmar
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
    </Sidebar>
  );
};

export default EditUserForm;
