import React, { useState } from 'react';
import { ChakraProvider } from '@chakra-ui/react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import theme from './Theme';  
import Login from './components/Login';
import Dashboard from './components/Dashboard';
import InvoicePage from './components/InvoicesPage';
import Users from './components/Users';
import CreateUserForm from './components/CreateUserForm';
import './index.css';
import Settings from './components/Settings';
import EditUserForm from './components/EditUserForm';
import AccessCompanies from './components/AccessCompanies'; // Importa el nuevo componente
import InvoiceNP from './components/InvoicesNP'; // Importa el nuevo componente


function App() {
  const [user, setUser] = useState<any>(null);
  const [company, setCompany] = useState<any>(null);

  const handleLoginSuccess = (user: any) => {
    console.log('User logged in:', user);
    setUser(user);
    setCompany(company);
  };



  return (
    <ChakraProvider theme={theme}>
      <Router>
        <Routes>
          <Route path="/login" element={<Login onLoginSuccess={handleLoginSuccess} />} />
          <Route path="/dashboard" element={user ? <Dashboard user={user}  /> : <Navigate to="/login" />} />
          <Route path="/invoices" element={user ? <InvoicePage user={user} /> : <Navigate to="/login" />} />
          <Route path="/invoicesNP" element={user ? <InvoiceNP user={user}/> : <Navigate to="/login" />} />
          <Route path="/settings" element={user ? <Settings user={user}  /> : <Navigate to="/login" />} />
          <Route path="/users" element={user ? <Users user={user}  /> : <Navigate to="/login" />} />
          <Route path="/create-user" element={user ? <CreateUserForm user={user}   /> : <Navigate to="/login" />} />
          <Route path="/edit-user/:id" element={user ? <EditUserForm user={user}  /> : <Navigate to="/login" />} />
          <Route path="/access-companies/:userId" element={user ? <AccessCompanies /> : <Navigate to="/login" />} /> 
          <Route path="*" element={<Navigate to="/login" />} />
        </Routes>
      </Router>
    </ChakraProvider>
  );
}

export default App;