import React from 'react';
import ReactDOM from 'react-dom/client'; // Importa desde 'react-dom/client'
import { ChakraProvider } from '@chakra-ui/react';
import App from './App.tsx';

const rootElement = document.getElementById('root');
const root = ReactDOM.createRoot(rootElement); // Usa createRoot en lugar de render

root.render(
  <React.StrictMode>
    <ChakraProvider>
      <App />
    </ChakraProvider>
  </React.StrictMode>
);
