import React, { useEffect, useState, useCallback } from "react";
import {
  Box,
  Button,
  HStack,
  Icon,
  Input,
  InputGroup,
  InputLeftElement,
  Table,
  TableContainer,
  Tbody,
  Td,
  Select,
  FormControl,
  Th,
  Thead,
  Tr,
  VStack,
  useToast,
  Checkbox,
} from "@chakra-ui/react";
import { FiFilter, FiSearch, FiDownload } from "react-icons/fi";
import { VscServerProcess } from "react-icons/vsc";
import apiClient from "./utils/Api";
import SidebarWithHeader from "./Sidebar";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { format } from "date-fns";

import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
} from "@chakra-ui/react";

const InvoicePage = ({ user }) => {
  const [invoices, setInvoices] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [startDate, setStartDate] = useState<Date | null>(null);
  const [endDate, setEndDate] = useState<Date | null>(null);
  const [searchQuery, setSearchQuery] = useState("");
  const [nom_empresa, setNomEmpresa] = useState("");
  const [emisorNit, setEmisorNit] = useState("");
  const [emisorNrc, setEmisorNrc] = useState("");
  const [numControl, setNumControl] = useState("");
  const [codGEN, setCodGen] = useState("");
  const [sello_recepcion, setSello] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [pdfUrl, setPdfUrl] = useState("");
  const [jsonData, setJsonData] = useState(null);
  const [isJsonModalOpen, setIsJsonModalOpen] = useState(false);
  const [estadoFacturas, setEstadoFacturas] = useState("");
  const [tipoFacturas, setTipoFacturas] = useState("");
  // Estados temporales para filtros
  const [tempSearchQuery, setTempSearchQuery] = useState("");
  const [tempNomEmpresa, setTempNomEmpresa] = useState("");
  const [tempEmisorNit, setTempEmisorNit] = useState("");
  const [tempEmisorNrc, setTempEmisorNrc] = useState("");
  const [tempNumControl, setTempNumControl] = useState("");
  const [tempCodGEN, setTempCodGen] = useState("");
  const [tempSelloRecepcion, setTempSelloRecepcion] = useState("");
  const [tempEstadoFacturas, setTempEstadoFacturas] = useState("");
  const [tempTipoFacturas, setTempTipoFacturas] = useState("");
  // const [tempStartDate, setTempStartDate] = useState<Date | null>(null);
  // const [tempEndDate, setTempEndDate] = useState<Date | null>(null);
  const nitCompany = localStorage.getItem("nitCompany");

  const toast = useToast();

  const fetchInvoices = useCallback(
    (page) => {
      let query = `/facturas/monitor-facturas?page=${page}&nombreEmpresa=${nom_empresa}&query=${searchQuery}&nit=${emisorNit}&nrc=${emisorNrc}&numControl=${numControl}&codGen=${codGEN}&sello=${sello_recepcion}&empresa=${nitCompany}&estado=${estadoFacturas}&tipoDte=${tipoFacturas}`;
      if (startDate && endDate) {
        query += `&startDate=${format(
          startDate,
          "yyyy-MM-dd"
        )}&endDate=${format(endDate, "yyyy-MM-dd")}`;
      }

      apiClient
        .get(query)
        .then((response) => {
          setInvoices(response.data.invoices);
          const { total, per_page } = response.data.pagination;
          setTotalPages(Math.ceil(total / per_page));
        })
        .catch((error) => {
          console.error("There was an error fetching the invoices!", error);
        });
    },
    [
      emisorNit,
      emisorNrc,
      numControl,
      codGEN,
      sello_recepcion,
      startDate,
      endDate,
      estadoFacturas,
      tipoFacturas,
      searchQuery,
      nom_empresa,
    ]
  );

  const FiltrarfetchInvoices = useCallback(
    (page) => {
      let query = `/facturas/monitor-facturas?page=${page}&nombreEmpresa=${nom_empresa}&query=${searchQuery}&nit=${emisorNit}&nrc=${emisorNrc}&numControl=${numControl}&codGen=${codGEN}&sello=${sello_recepcion}&empresa=${nitCompany}&estado=${estadoFacturas}`;
      if (startDate && endDate) {
        query += `&startDate=${format(
          startDate,
          "yyyy-MM-dd"
        )}&endDate=${format(endDate, "yyyy-MM-dd")}`;
      }

      apiClient
        .get(query)
        .then((response) => {
          setInvoices(response.data.invoices);
          const { total, per_page } = response.data.pagination;
          setTotalPages(Math.ceil(total / per_page));
        })
        .catch((error) => {
          console.error("There was an error fetching the invoices!", error);
        });
    },
    [
      emisorNit,
      emisorNrc,
      numControl,
      codGEN,
      sello_recepcion,
      startDate,
      endDate,
      estadoFacturas,
      nom_empresa,
    ]
  );

  useEffect(() => {
    fetchInvoices(currentPage);
  }, [currentPage, fetchInvoices]);

  const handlePreviousPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
      window.scrollTo({ top: 0, behavior: "auto" });
    }
  };

  const handleNextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
      window.scrollTo({ top: 0, behavior: "auto" });
    }
  };

  const handlePageClick = (page) => {
    if (page === "...") return; // Evita que los puntos suspensivos sean clicables
    setCurrentPage(page);
    window.scrollTo({ top: 0, behavior: "auto" });
  };

  const handleFilterClick = () => {
    setCurrentPage(1);
    setIsFilterModalOpen(true);
  };

  // const handleEstadoFacturaClick = (
  //   event: React.ChangeEvent<HTMLSelectElement>
  // ) => {
  //   setEstadoFacturas(event.target.value);
  //   setCurrentPage(1);
  // };

  // Handlers para los inputs que actualizan los estados temporales
  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
    setCurrentPage(1);
  };

  const handleNombreEmpresa = (event) => {
    setTempNomEmpresa(event.target.value);
  };

  const handleNitChange = (event) => {
    setTempEmisorNit(event.target.value);
  };

  const handleNrcChange = (event) => {
    setTempEmisorNrc(event.target.value);
  };

  const handleNumControlChange = (event) => {
    setTempNumControl(event.target.value);
  };

  const handlecodGenChange = (event) => {
    setTempCodGen(event.target.value);
  };

  const handleSelloChange = (event) => {
    setTempSelloRecepcion(event.target.value);
  };

  const handleEstadoFacturaClick = (
    event: React.ChangeEvent<HTMLSelectElement>
  ) => {
    setTempEstadoFacturas(event.target.value);
    setCurrentPage(1);
  };
  
  const handleTipoFacturaClick = (
    event: React.ChangeEvent<HTMLSelectElement>
  ) => {
    setTempTipoFacturas(event.target.value);
    setCurrentPage(1);
  };

  const handleCellClick = (text) => {
    navigator.clipboard
      .writeText(text)
      .then(() => {
        toast({
          title: "Texto copiado",
          description: "El texto ha sido copiado al portapapeles.",
          status: "success",
          duration: 3000,
          isClosable: true,
        });
      })
      .catch((err) => {
        console.error("Error al copiar texto: ", err);
      });
  };

  const handleDownloadClick = () => {
    if (!startDate || !endDate) {
      // Verificar también que se ingrese el NIT
      toast({
        title: "Datos inválidos",
        description: "Por favor, seleccione un rango de fechas.",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
      return;
    }

    const start = format(startDate, "yyyy-MM-dd");
    const end = format(endDate, "yyyy-MM-dd");

    apiClient
      .get(
        `/facturas/downloads-dtes?startDate=${start}&endDate=${end}&nitReceptor=${nitCompany}`,
        {
          // Agregar nitReceptor solicitud
          responseType: "blob",
          headers: {
            "Content-Type": "application/zip",
          },
        }
      )
      .then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", `FACTURAS_${start}_${end}.zip`);
        document.body.appendChild(link);
        link.click();
        link.remove();
        window.URL.revokeObjectURL(url);
      })
      .catch((error) => {
        console.error("Error al descargar los DTEs:", error);
        toast({
          title: "Error de descarga",
          description:
            "No tienes facturas disponibles para descargar. Inténtalo de nuevo más tarde.",
          status: "error",
          duration: 3000,
          isClosable: true,
        });
      });
  };

  // Asegúrate de que invoices esté en el estado correcto antes de filtrar
  const handleSubmit = () => {
    // Asegurarse de que el estado esté sincronizado
    setInvoices((prev) => {
      console.log("prev:", prev);

      // Filtrar las facturas seleccionadas
      const selectedInvoices = prev.filter((invoice) => invoice.selected);
      const selectedInvoiceIds = selectedInvoices.map((invoice) => invoice.id);

      // Si no hay facturas seleccionadas, mostrar un mensaje de advertencia
      if (selectedInvoices.length === 0) {
        toast({
          title: "No hay facturas seleccionadas",
          description:
            "Por favor, seleccione al menos una factura para procesar.",
          status: "warning",
          duration: 3000,
          isClosable: true,
        });
        return prev; // Salir de la función si no hay facturas seleccionadas
      }

      console.log("Facturas seleccionadas:", selectedInvoices);

      // Enviar los IDs de las facturas seleccionadas al backend
      apiClient
        .post("/facturas/actualizar-facturas", { id_list: selectedInvoiceIds })
        .then((response) => {
          // Mostrar mensaje de éxito si las facturas fueron procesadas correctamente
          toast({
            title: "Facturas procesadas",
            description:
              "Las facturas seleccionadas han sido procesadas exitosamente.",
            status: "success",
            duration: 3000,
            isClosable: true,
          });

          // Actualizar la lista de facturas si es necesario
          fetchInvoices(currentPage);
        })
        .catch((error) => {
          // Manejar errores en la petición al backend
          console.error("Error al procesar las facturas:", error);
          toast({
            title: "Error",
            description:
              "Hubo un error al procesar las facturas. Intente nuevamente más tarde.",
            status: "error",
            duration: 3000,
            isClosable: true,
          });
        });

      return prev; // Retornar el estado previo
    });
  };
  const userName = user.username;
  const userType = user.tipo === "A" ? "Administrador" : "Contador";
  const getPageNumbers = () => {
    const pageNumbers = [];
    const maxPagesToShow = 5; // Número máximo de páginas visibles
    const halfMaxPagesToShow = Math.floor(maxPagesToShow / 2);

    if (totalPages <= maxPagesToShow) {
      for (let i = 1; i <= totalPages; i++) {
        pageNumbers.push(i);
      }
    } else {
      if (currentPage <= halfMaxPagesToShow) {
        for (let i = 1; i <= maxPagesToShow - 1; i++) {
          pageNumbers.push(i);
        }
        pageNumbers.push("...");
        pageNumbers.push(totalPages);
      } else if (currentPage >= totalPages - halfMaxPagesToShow) {
        pageNumbers.push(1);
        pageNumbers.push("...");
        for (let i = totalPages - (maxPagesToShow - 2); i <= totalPages; i++) {
          pageNumbers.push(i);
        }
      } else {
        pageNumbers.push(1);
        pageNumbers.push("...");
        for (let i = currentPage - 1; i <= currentPage + 1; i++) {
          pageNumbers.push(i);
        }
        pageNumbers.push("...");
        pageNumbers.push(totalPages);
      }
    }

    return pageNumbers;
  };
  // funcion para ver PDFs
  const handleViewPDFClick = async (codGen) => {
    try {
      const response = await apiClient.get(`/facturas/pdf/${codGen}`);
      const url = response.data.pdf_url;
      console.log("PDF URL:", url); // Verifica que la URL se obtiene correctamente
      setPdfUrl(url);
      console.log("Opening modal..."); // Verifica que este código se ejecuta
      setIsModalOpen(true); // Intenta abrir el modal
    } catch (error) {
      console.error("Error fetching the PDF URL:", error);
      toast({
        title: "Error al cargar el PDF",
        description:
          "No se pudo cargar el archivo PDF. Inténtalo de nuevo más tarde.",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    }
  };

  // Limpiar filtros: Resetea los estados temporales y los valores reales
  const handleClearFilters = () => {
    setTempSearchQuery("");
    setTempNomEmpresa("");
    setTempEmisorNit("");
    setTempEmisorNrc("");
    setTempNumControl("");
    setTempCodGen("");
    setTempSelloRecepcion("");
    setTempEstadoFacturas("");
    setTempTipoFacturas("");
    // setTempStartDate(null);
    // setTempEndDate(null);

    setSearchQuery("");
    setNomEmpresa("");
    setEmisorNit("");
    setEmisorNrc("");
    setNumControl("");
    setCodGen("");
    setSello("");
    setEstadoFacturas("");
    setTipoFacturas("");
    setStartDate(null);
    setEndDate(null);
    setCurrentPage(1); // Esto disparará fetchInvoices con todos los filtros limpios
    setIsFilterModalOpen(false);
  };

  const handleCloseFilters = () => {
    // Cerrar el modal de filtros
    setIsFilterModalOpen(false);
  };

  // Funcion para ver dtes
  const handleViewJsonClick = async (codGen) => {
    try {
      const response = await apiClient.get(`/facturas/dte/${codGen}`);
      if (response.data.dte) {
        setJsonData(response.data.dte);
        setIsJsonModalOpen(true);
      } else {
        toast({
          title: "DTE no encontrado",
          description: "No se encontró el DTE para este código.",
          status: "error",
          duration: 3000,
          isClosable: true,
        });
      }
    } catch (error) {
      console.error("Error fetching the JSON data:", error);
      toast({
        title: "Error al cargar el DTE",
        description: "No se pudo cargar el DTE. Inténtalo de nuevo más tarde.",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    }
  };

  const handleJsonDownload = (data) => {
    // Convertir el objeto JSON a una cadena formateada
    const jsonString = JSON.stringify(data, null, 2);

    // Crear un Blob con el contenido del archivo
    const blob = new Blob([jsonString], { type: "application/json" });

    // Crear un enlace para la descarga
    const url = URL.createObjectURL(blob);
    const link = document.createElement("a");

    // Especificar el nombre del archivo
    link.href = url;
    link.download = "data.json";

    // Simular el clic en el enlace para iniciar la descarga
    document.body.appendChild(link);
    link.click();

    // Eliminar el enlace del DOM después de la descarga
    document.body.removeChild(link);
  };

  // Modal filtrar.
  const [isFilterModalOpen, setIsFilterModalOpen] = useState(false);

  // Aplicar filtros: Transfiere los valores temporales a los valores reales usados para fetch
  const handleApplyFilters = () => {
    setSearchQuery(tempSearchQuery);
    setNomEmpresa(tempNomEmpresa);
    setEmisorNit(tempEmisorNit);
    setEmisorNrc(tempEmisorNrc);
    setNumControl(tempNumControl);
    setCodGen(tempCodGEN);
    setSello(tempSelloRecepcion);
    setEstadoFacturas(tempEstadoFacturas);
    setTipoFacturas(tempTipoFacturas);
    // setStartDate(tempStartDate);
    // setEndDate(tempEndDate);
    setCurrentPage(1); // Esto disparará fetchInvoices con los nuevos valores
    setIsFilterModalOpen(false); // Cierra el modal de filtros
  };

  return (
    <SidebarWithHeader userName={userName} tipo={userType}>
      <Box p="4" width="100%" height="100%">
        <VStack align="start" bg="gray.100" spacing="4" width="100%">
          <HStack width="100%" padding="20px">
            <InputGroup size="sm" w="200px">
              <InputLeftElement
                pointerEvents="none"
                height="100%"
                display="flex"
                alignItems="center"
                ml="2"
              >
                <Icon as={FiSearch} color="gray.300" boxSize="15px" />
              </InputLeftElement>
              <Input
                placeholder="Buscar"
                value={searchQuery}
                onChange={handleSearchChange}
                background="#FFFFFF"
                height="41px"
                rounded="25px"
              />
            </InputGroup>
            <Box>
              <DatePicker
                selected={startDate}
                onChange={(date: Date | null) => {
                  setStartDate(date);
                  if (date && (!endDate || date > endDate)) {
                    setEndDate(date);
                  }
                }}
                placeholderText="Desde"
                dateFormat="dd/MM/yyyy"
                isClearable
                className="datepicker"
                maxDate={endDate || undefined}
              />
            </Box>
            <Box>
              <DatePicker
                selected={endDate}
                onChange={(date: Date | null) => setEndDate(date)}
                placeholderText="Hasta"
                dateFormat="dd/MM/yyyy"
                isClearable
                className="datepicker"
                startDate={startDate}
                minDate={startDate || undefined}
              />
            </Box>
            <HStack spacing="2">
              <Button
                bg="#5459EA"
                color="#ffff"
                borderRadius="100px"
                rightIcon={<Icon as={FiFilter} />}
                onClick={handleFilterClick}
                _hover={{
                  bg: "purple.600",
                  color: "white",
                }}
              >
                Filtrar
              </Button>
            </HStack>
            <HStack>
              <Button
                bg="#5459EA"
                color="#ffff"
                borderRadius="100px"
                rightIcon={<Icon as={VscServerProcess} />}
                onClick={handleSubmit}
                _hover={{
                  bg: "purple.600",
                  color: "white",
                }}
              >
                Procesar
              </Button>
              <Button
                bg="#5459EA"
                color="#ffff"
                borderRadius="100px"
                rightIcon={<Icon as={FiDownload} />}
                onClick={handleDownloadClick}
                _hover={{
                  bg: "purple.600",
                  color: "white",
                }}
              >
                Descargar
              </Button>
            </HStack>
          </HStack>

          <Box width="100%" overflowX="auto">
            <TableContainer width="100%">
              <Table variant="simple" size="sm" width="100%">
                <Thead zIndex="0" width="100%">
                  <Tr height="60px" bg="#F5F7FB">
                    <Th
                      fontSize="14px"
                      textAlign="left"
                      minWidth="150px"
                      maxWidth="200px"
                      p="5"
                      style={{
                        wordWrap: "break-word",
                        whiteSpace: "normal",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        textTransform: "none",
                      }}
                    >
                      Nombre de emisor
                    </Th>
                    <Th
                      textAlign="center"
                      fontSize="14px"
                      minWidth="140px"
                      maxWidth="120px"
                      style={{
                        wordWrap: "break-word",
                        whiteSpace: "normal",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        textTransform: "none", // Asegura que el texto no sea transformado a mayúsculas
                      }}
                    >
                      NRC Emisor
                    </Th>
                    <Th
                      textAlign="center"
                      minWidth="120px"
                      fontSize="14px"
                      maxWidth="150px"
                      style={{
                        wordWrap: "break-word",
                        whiteSpace: "normal",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        textTransform: "none", // Asegura que el texto no sea transformado a mayúsculas
                      }}
                    >
                      NIT Emisor
                    </Th>
                    <Th
                      textAlign="center"
                      minWidth="100px"
                      fontSize="14px"
                      maxWidth="120px"
                      style={{
                        wordWrap: "break-word",
                        whiteSpace: "normal",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        textTransform: "none", // Asegura que el texto no sea transformado a mayúsculas
                      }}
                    >
                      Fecha
                    </Th>
                    <Th
                      textAlign="center"
                      fontSize="14px"
                      minWidth="140px"
                      maxWidth="150px"
                      style={{
                        wordWrap: "break-word",
                        whiteSpace: "normal",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        textTransform: "none", // Asegura que el texto no sea transformado a mayúsculas
                      }}
                    >
                      Número de control
                    </Th>
                    <Th
                      textAlign="center"
                      minWidth="150px"
                      maxWidth="120px"
                      fontSize="14px"
                      style={{
                        wordWrap: "break-word",
                        whiteSpace: "normal",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        textTransform: "none", // Asegura que el texto no sea transformado a mayúsculas
                      }}
                    >
                      Código Generación
                    </Th>
                    <Th
                      textAlign="center"
                      minWidth="150px"
                      maxWidth="120px"
                      fontSize="14px"
                      style={{
                        wordWrap: "break-word",
                        whiteSpace: "normal",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        textTransform: "none", // Asegura que el texto no sea transformado a mayúsculas
                      }}
                    >
                      Sello de Recepción
                    </Th>
                    <Th
                      textAlign="right"
                      minWidth="100px"
                      maxWidth="120px"
                      fontSize="14px"
                      style={{
                        wordWrap: "break-word",
                        whiteSpace: "normal",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        textTransform: "none",
                      }}
                    >
                      Monto
                    </Th>
                    <Th
                      textAlign="left"
                      minWidth="80px"
                      fontSize="14px"
                      maxWidth="100px"
                      style={{
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        textTransform: "none",
                      }}
                    >
                      Procesar
                    </Th>
                    <Th
                      textAlign="center"
                      minWidth="80px"
                      maxWidth="100px"
                      fontSize="14px"
                      style={{
                        wordWrap: "break-word",
                        whiteSpace: "normal",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        textTransform: "none",
                      }}
                    >
                      Acciones
                    </Th>
                  </Tr>
                </Thead>

                <Tbody bg="#FCFCFC" zIndex="-1">
                  {invoices.length === 0 ? (
                    <Tr>
                      <Td
                        colSpan={10}
                        textAlign="center"
                        color="gray.200"
                        fontSize="md"
                      >
                        Actualmente, no hay facturas disponibles en el sistema.
                      </Td>
                    </Tr>
                  ) : (
                    invoices.map((invoice, index) => (
                      <Tr key={index}>
                        <Td
                          height="50px"
                          color="#898989"
                          fontSize="sm"
                          style={{
                            wordWrap: "break-word",
                            wordBreak: "break-all",
                            whiteSpace: "normal",
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                          }}
                          onClick={() => handleCellClick(invoice.nom_emisor)}
                        >
                          {invoice.nom_emisor}
                        </Td>
                        <Td
                          color="#898989"
                          fontSize="sm"
                          textAlign="center"
                          style={{
                            wordWrap: "break-word",
                            wordBreak: "break-all",
                            whiteSpace: "normal",
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                          }}
                          onClick={() => handleCellClick(invoice.nrc_emisor)}
                        >
                          {invoice.nrc_emisor}
                        </Td>
                        <Td
                          color="#898989"
                          fontSize="sm"
                          textAlign="center"
                          style={{
                            wordWrap: "break-word",
                            wordBreak: "break-all",
                            whiteSpace: "normal",
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                          }}
                          onClick={() => handleCellClick(invoice.nit_emisor)}
                        >
                          {invoice.nit_emisor}
                        </Td>
                        <Td color="#898989" fontSize="sm" textAlign="center">
                          {invoice.fecha_emision
                            ? format(
                                new Date(invoice.fecha_emision),
                                "dd/MM/yyyy"
                              )
                            : ""}
                        </Td>
                        <Td
                          color="#898989"
                          fontSize="sm"
                          textAlign="center"
                          style={{
                            wordWrap: "break-word",
                            wordBreak: "break-all",
                            whiteSpace: "normal",
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                          }}
                          onClick={() => handleCellClick(invoice.num_control)}
                        >
                          {invoice.num_control}
                        </Td>
                        <Td
                          color="#898989"
                          fontSize="sm"
                          textAlign="center"
                          style={{
                            wordWrap: "break-word",
                            wordBreak: "break-all",
                            whiteSpace: "normal",
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                          }}
                          onClick={() => handleCellClick(invoice.cod_gen)}
                        >
                          {invoice.cod_gen}
                        </Td>
                        <Td
                          color="#898989"
                          fontSize="sm"
                          textAlign="center"
                          style={{
                            wordWrap: "break-word",
                            wordBreak: "break-all",
                            whiteSpace: "normal",
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                          }}
                          onClick={() =>
                            handleCellClick(invoice.sello_recepcion)
                          }
                        >
                          {invoice.sello_recepcion}
                        </Td>
                        <Td color="#898989" fontSize="sm" textAlign="right">
                          ${invoice.monto}
                        </Td>
                        <Td>
                          <Checkbox
                            isChecked={invoice.selected || false}
                            isDisabled={invoices.some(
                              (inv) =>
                                inv.id === invoice.id && inv.procesada === "1"
                            )}
                            onChange={(e) => {
                              console.log(
                                `Factura ID: ${invoice.id}, Seleccionada: ${e.target.checked}`
                              );
                              setInvoices((prev) =>
                                prev.map((inv) =>
                                  inv.id === invoice.id
                                    ? { ...inv, selected: e.target.checked }
                                    : inv
                                )
                              );
                            }}
                          />
                        </Td>
                        <Td>
                          <VStack spacing="2">
                            <Button
                              width="300"
                              height="7"
                              color="#ffff"
                              bg="#FBBC05"
                              borderRadius="100px"
                              onClick={() =>
                                handleViewPDFClick(invoice.cod_gen)
                              }
                            >
                              Ver PDF
                            </Button>
                            <Button
                              width="300"
                              height="7"
                              color="#ffff"
                              bg="#828282"
                              borderRadius="100px"
                              fontSize="13px"
                              onClick={() =>
                                handleViewJsonClick(invoice.cod_gen)
                              }
                            >
                              VER JSON
                            </Button>
                          </VStack>
                        </Td>
                      </Tr>
                    ))
                  )}
                </Tbody>
              </Table>
            </TableContainer>
          </Box>
        </VStack>
      </Box>

      <Box bg="white">
        <HStack spacing="4" justify="flex-end">
          <Button onClick={handlePreviousPage} isDisabled={currentPage === 1}>
            {"<"}
          </Button>

          {getPageNumbers().map((page, index) => (
            <Button
              key={index}
              onClick={() => handlePageClick(page)}
              colorScheme={currentPage === page ? "blue" : "white"}
              variant={currentPage === page ? "solid" : "outline"}
            >
              {page}
            </Button>
          ))}

          <Button
            onClick={handleNextPage}
            isDisabled={currentPage === totalPages}
          >
            {">"}
          </Button>
        </HStack>
      </Box>

      <Modal
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        size="1xl"
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader textAlign="center">Vista Previa del PDF</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            {pdfUrl ? (
              <div style={{ overflow: "auto" }}>
                <iframe
                  src={pdfUrl}
                  width="100%"
                  height="600px"
                  title="PDF Preview"
                  style={{ border: "none" }}
                />
              </div>
            ) : (
              <p>Cargando PDF...</p>
            )}
          </ModalBody>
          <ModalFooter>
            <Button
              ml="10px"
              bg="purple.600"
              colorScheme="purple"
              width="100%"
              maxWidth="150px"
              borderRadius="100px"
              fontWeight="regular"
              height="30px"
              display="block"
              onClick={() => setIsModalOpen(false)}
            >
              Cerrar
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
      <Modal
        isOpen={isJsonModalOpen}
        onClose={() => setIsJsonModalOpen(false)}
        size="xl"
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader fontSize="lg" fontWeight="bold" color="teal.600">
            Vista previa del DTE
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody p={6} bg="gray.50" borderRadius="md" boxShadow="md">
            <Box
              bg="white"
              borderRadius="md"
              p={4}
              borderWidth="1px"
              borderColor="gray.200"
              overflow="auto"
              maxHeight="400px"
            >
              <pre
                style={{
                  whiteSpace: "pre-wrap",
                  wordWrap: "break-word",
                  fontFamily: "monospace",
                  backgroundColor: "#f7f7f7",
                  padding: "10px",
                  borderRadius: "4px",
                  border: "1px solid #ddd",
                }}
              >
                {jsonData}
              </pre>
            </Box>
          </ModalBody>
          <ModalFooter>
            <Button
              borderRadius="100px"
              width="100px"
              fontWeight="regular"
              height="30px"
              color="gray.200"
              bg="gray.150"
              _hover={{
                bg: "gray.150",
                color: "gray.200",
              }}
              onClick={() => setIsJsonModalOpen(false)}
            >
              Cerrar
            </Button>
            <Button
              ml="10px"
              bg="purple.600"
              colorScheme="purple"
              width="100%"
              maxWidth="150px"
              borderRadius="100px"
              fontWeight="regular"
              height="30px"
              display="block"
              onClick={() => handleJsonDownload(jsonData)}
            >
              Descargar JSON
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>

      <Modal
        isOpen={isFilterModalOpen}
        onClose={() => setIsFilterModalOpen(false)}
        closeOnOverlayClick={false}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader textAlign="center">Filtros Avanzados</ModalHeader>
          <ModalCloseButton
            onClick={() => {
              handleCloseFilters();
            }}
          />
          <ModalBody p="45px" mt="-45px" mb="-30px">
            <VStack spacing={4}>
              <Input
                placeholder="Buscar por nombre de la empresa"
                value={tempNomEmpresa}
                onChange={handleNombreEmpresa}
                borderRadius="12px"
                padding="16px"
                color="#18181B"
              />
              <Input
                placeholder="Buscar por NRC Emisor"
                value={tempEmisorNrc}
                onChange={handleNrcChange}
                borderRadius="12px"
                padding="16px"
                color="#18181B"
              />
              <Input
                placeholder="Buscar por NIT Emisor"
                value={tempEmisorNit}
                onChange={handleNitChange}
                borderRadius="12px"
                padding="16px"
                color="#18181B"
              />
              <Input
                placeholder="Buscar por Número de Control"
                value={tempNumControl}
                onChange={handleNumControlChange}
                borderRadius="12px"
                padding="16px"
                color="#18181B"
              />
              <Input
                placeholder="Buscar por Código de generación"
                value={tempCodGEN}
                onChange={handlecodGenChange}
                borderRadius="12px"
                padding="16px"
                color="#18181B"
              />
              <Input
                placeholder="Buscar por Sello de Recepción"
                value={tempSelloRecepcion}
                onChange={handleSelloChange}
                borderRadius="12px"
                padding="16px"
                color="#18181B"
              />
              <FormControl>
                <Select
                  color="#18181B"
                  borderRadius="12px"
                  value={tempTipoFacturas}
                  onChange={handleTipoFacturaClick}
                >
                  <option value="">Todos los tipos de documentos</option>
                  <option value="01">Consumidor Final</option>
                  <option value="03">Credito Fiscal</option>
                  <option value="05">Nota de Credito</option>
                  <option value="04">Nota de Remision</option>
                  <option value="07">Comprobante de Retencion</option>
                </Select>
              </FormControl>
              <FormControl>
                <Select
                  color="#18181B"
                  borderRadius="12px"
                  value={tempEstadoFacturas}
                  onChange={handleEstadoFacturaClick}
                >
                  <option value="">Todas</option>
                  <option value="1">Procesadas</option>
                  <option value="0">No Procesadas</option>
                </Select>
              </FormControl>
            </VStack>
          </ModalBody>
          <ModalFooter
            display="flex"
            justifyContent="center" // Centra los botones horizontalmente
          >
            <Button
              ml="10px"
              bg="gray.150"
              colorScheme="gray.150"
              color="gray.200"
              width="100%"
              maxWidth="180px"
              borderRadius="100px"
              fontWeight="regular"
              height="30px"
              display="block"
              onClick={handleClearFilters} // Llama a la función para limpiar los filtros
            >
              Limpiar Filtros
            </Button>
            <Button
              ml="10px"
              bg="purple.600"
              colorScheme="purple"
              width="100%"
              maxWidth="180px"
              borderRadius="100px"
              fontWeight="regular"
              height="30px"
              display="block"
              onClick={handleApplyFilters} // Llama a la función para aplicar los filtros
            >
              Aplicar Filtros
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </SidebarWithHeader>
  );
};

export default InvoicePage;
