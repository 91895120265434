import React, { useEffect, useState, useRef } from 'react';
import {
  Box, Button, Text, Table, Thead, Tbody, Tr, Th, Td, useToast, Spinner, IconButton,
  AlertDialog, AlertDialogBody, AlertDialogFooter, AlertDialogHeader, AlertDialogContent,
  AlertDialogOverlay
} from '@chakra-ui/react';
import { useParams, useLocation, useNavigate } from 'react-router-dom';
import { BsArrowLeft } from "react-icons/bs";
import Sidebar from './Sidebar';
import apiClient from './utils/Api';

interface Company {
  id: number;
  nombre: string;
  nrc: string;
  nit: string;
  estado: string;
  telefono: string;
}

const AccessCompanies: React.FC = () => {
  const { userId } = useParams<{ userId: string }>();
  const location = useLocation();
  const navigate = useNavigate();
  const toast = useToast();
  const { user } = location.state as { user: { username: string; tipo: string; estado: string } };
  const userType = user.tipo === "A" ? "Administrador" : "Contador";

  const [companies, setCompanies] = useState<Company[]>([]);
  const [selectedCompanies, setSelectedCompanies] = useState<Set<number>>(new Set());
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);

  // Estado para el modal de confirmación
  const [isOpen, setIsOpen] = useState(false);
  const cancelRef = useRef<HTMLButtonElement>(null);

  useEffect(() => {
    const fetchUserCompanies = async () => {
      try {
        const response = await apiClient.get(`/empresas/users/${userId}/companies`);
        setCompanies(response.data);
        const companyIds = new Set<number>(response.data.filter((company: Company) => company.estado === 'A').map((company: { id: number }) => company.id));
        setSelectedCompanies(companyIds);
      } catch (error) {
        setError('Error fetching user companies');
      } finally {
        setLoading(false);
      }
    };

    fetchUserCompanies();
  }, [userId]);

  const handleToggleAccess = (companyId: number) => {
    setCompanies(prevCompanies =>
      prevCompanies.map(company =>
        company.id === companyId
          ? { ...company, estado: company.estado === 'A' ? 'I' : 'A' }
          : company
      )
    );
    setSelectedCompanies(prevSelectedCompanies => {
      const newSelectedCompanies = new Set(prevSelectedCompanies);
      if (newSelectedCompanies.has(companyId)) {
        newSelectedCompanies.delete(companyId);
      } else {
        newSelectedCompanies.add(companyId);
      }
      return newSelectedCompanies;
    });
  };

  const handleSaveChanges = async () => {
    try {
      const companiesToUpdate = companies.map(company => ({
        id_empresa: company.id,
        estado: company.estado
      }));

      await apiClient.post(`/empresas/users/${userId}/update-companies`, {
        companies: companiesToUpdate
      });
      toast({
        title: "Éxito!",
        description: "Cambios guardados con éxito.",
        status: "success",
        duration: 5000,
        position: "top-right",
        isClosable: true,
      });
      navigate('/users')
    } catch (error) {
      setError('Error saving changes');
      toast({
        title: "Error",
        description: "Hubo un problema al guardar los cambios.",
        status: "error",
        position: "top-right",
        duration: 5000,
        isClosable: true,
      });
    } finally {
      setIsOpen(false); 
    }
  };

  const openModal = () => setIsOpen(true);
  const closeModal = () => setIsOpen(false);

  if (loading) {
    return <Spinner />;
  }

  return (
    <Sidebar userName={user.username} tipo={userType}>
      <Box display="flex" alignItems="center" mt="1%" mb="2%">
        <IconButton
          aria-label="Ir al panel de usuarios"
          icon={<BsArrowLeft />}
          ml="4"
          variant="ghost"
          onClick={() => navigate('/users')}
          colorScheme="black"
        />
        <Text fontWeight="medium" fontFamily="DM Sans" ml="1">
          Otorgar Accesos
        </Text>
      </Box>
      <Box pt={4}>
        {error && <Text color="red.500" textAlign="center">{error}</Text>}
        <Table variant="simple" border="none" textTransform="none">
          <Thead bg="gray.100">
            <Tr>
              <Th textTransform="none" fontSize="15px" textAlign="center">Nombre Empresa</Th>
              <Th textTransform="none" fontSize="15px" textAlign="left">NRC</Th>
              <Th textTransform="none" fontSize="15px" textAlign="left">NIT</Th>
              <Th textTransform="none" fontSize="15px" textAlign="center">Telefono</Th>
              <Th textTransform="none" fontSize="15px" textAlign="center">Acceso</Th>
            </Tr>
          </Thead>
          <Tbody>
            {companies.map((company) => (
              <Tr key={company.id}>
                <Td verticalAlign="middle" border="none" textAlign="left">{company.nombre}</Td>
                <Td verticalAlign="middle" border="none" textAlign="left">{company.nrc}</Td>
                <Td verticalAlign="middle" border="none" textAlign="left">{company.nit}</Td>
                <Td verticalAlign="middle" border="none" textAlign="center">{company.telefono}</Td>
                <Td verticalAlign="middle" border="none" textAlign="center">
                  <Button
                    onClick={() => handleToggleAccess(company.id)}
                    bg={company.estado === 'A' ? "purple.600" : "#DDDDDD"}
                    color={company.estado === 'A' ? "#fff" : "#888888"}
                    borderRadius="100px"
                    width="100px"
                    fontWeight="regular"
                    height="30px"
                    _hover={{
                      bg: company.estado === 'A' ? "purple.600" : "#CCCCCC",
                      color: company.estado === 'A' ? "#fff" : "#666666"
                    }}
                  >   
                    {company.estado === 'A' ? 'Activo' : 'Inactivo'}
                  </Button>
                </Td>
              </Tr>
            ))}
          </Tbody>
        </Table>
        <Box mt="4" textAlign="left">
          <Button
            mt="3%"
            colorScheme="purple"
            bg="purple.600"
            width="10%"
            borderRadius="10px"
            fontWeight="regular"
            height="35px"
            ml="2%"
            onClick={openModal} 
          >
            Guardar
          </Button>
        </Box>
      </Box>

      <AlertDialog
        isOpen={isOpen}
        leastDestructiveRef={cancelRef}
        onClose={closeModal}
        closeOnOverlayClick={false}
      >
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize="lg" fontWeight="bold">
              Confirmar Cambios
            </AlertDialogHeader>
            <AlertDialogBody>
              ¿Estás seguro de que deseas otorgar estos accesos al usuario?
            </AlertDialogBody>
            <AlertDialogFooter>
              <Button 
                borderRadius="100px"
                width="100px"
                fontWeight="regular"
                height="30px"
                color="gray.200"
                bg="gray.150"
                _hover={{ 
                  bg: "gray.150", 
                  color: "gray.200" 
                }} 
                ref={cancelRef}
                onClick={closeModal} 
              >
                Cancelar
              </Button>
              <Button 
                borderRadius="100px"
                width="100px"
                fontWeight="medium"
                height="30px"
                color="white"
                bg="purple.600"
                _hover={{ 
                  bg: "purple.600", 
                  color: "white" 
                }} 
                onClick={handleSaveChanges}
                ml={3}
              >
                Confirmar
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
    </Sidebar>
  );
};

export default AccessCompanies;
