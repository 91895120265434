import React from 'react';
import { Checkbox } from '@chakra-ui/react';
import { ReactComponent as CustomCheckIcon } from '../../static/img/Checked-Icon.svg';

interface CustomCheckboxProps {
  isChecked: boolean;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

const CustomCheckbox: React.FC<CustomCheckboxProps> = ({ isChecked, onChange }) => {
  return (
    <Checkbox
      icon={<CustomCheckIcon style={{ width: '24px', height: '24px' }} />}
      colorScheme="purple"
      sx={{
        '& .chakra-checkbox__control': {
          borderColor: 'gray.50',
          bg: 'gray.50',
          width: '18px', // Ajusta el tamaño del control
          height: '18px', // Ajusta el tamaño del control
        },
        '& .chakra-checkbox__control[data-checked]': {
          bg: 'purple.400',
          borderColor: 'purple.400',
        },
        '& .chakra-checkbox__control[data-checked] .chakra-checkbox__icon': {
          color: 'white',
        },
        '& .chakra-checkbox__label': {
          color: 'purple.500',
          fontWeight: 'regular',
        },
      }}
      isChecked={isChecked}
      onChange={onChange}
    >
      Mantener sesión conectada
    </Checkbox>
  );
};

export default CustomCheckbox;
