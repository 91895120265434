import React, { useState, useEffect } from "react";
import { Box, Input, FormControl, FormLabel, HStack, Text } from "@chakra-ui/react";
import Sidebar from "./Sidebar";
import apiClient from "./utils/Api";

const Settings = ({ user}) => {
  const [nombre, setNombre] = useState("");
  const [apellido, setApellido] = useState("");
  const [usuario, setUsuario] = useState("");
  const [correo, setCorreo] = useState("");
  const nameCompany = localStorage.getItem("nameCompany");
  const nitCompany = localStorage.getItem("nitCompany");

  useEffect(() => {
    // Cargar los datos del usuario por ID
    const fetchUserData = async () => {
      try {
        const response = await apiClient.get(`/usuarios/users/${user.id}`);
        const userData = response.data;
        setNombre(userData.nombre);
        setApellido(userData.apellido);
        setUsuario(userData.usuario);
        setCorreo(userData.correo);
      } catch (error) {
        console.error("Error fetching user data:", error);
      }
    };

    fetchUserData();
  }, [user.id]);

  return (
    <Sidebar userName={user.username} tipo={user.tipo === "A" ? "Administrador" : "Contador"}>
      <Text mt="20px" fontWeight="medium" color="#27272A" fontFamily="DM Sans" ml="5">
        Información del Usuario
      </Text>
      <Box width="90%" mx="auto" mt="5">
        <Box width="100%"  mx="auto">
          <HStack spacing="4" mb="4">
            <FormControl id="usuario">
              <FormLabel fontFamily="DM Sans" fontWeight="regular" ml="1">
                Usuario
              </FormLabel>
              <Input color="#18181B" fontFamily="DM Sans" padding="16px" value={usuario} readOnly borderRadius="12px" />
            </FormControl>

            <FormControl id="correo">
              <FormLabel fontFamily="DM Sans" fontWeight="regular" ml="1">
                Correo
              </FormLabel>
              <Input color="#18181B" type="email" padding="16px" fontFamily="DM Sans" value={correo} isReadOnly borderRadius="12px" />
            </FormControl>
          </HStack>
          <HStack spacing="4" mb="4">
            <FormControl id="nombre">
              <FormLabel fontFamily="DM Sans" fontWeight="regular" ml="1">
                Nombre
              </FormLabel>
              <Input color="#18181B" value={nombre} padding="16px" fontFamily="DM Sans" isReadOnly borderRadius="12px" />
            </FormControl>

            <FormControl id="apellido">
              <FormLabel fontFamily="DM Sans" fontWeight="regular" ml="1">
                Apellido
              </FormLabel>
              <Input fontFamily="DM Sans" padding="16px" color="#18181B" value={apellido} isReadOnly borderRadius="12px" />
            </FormControl>
          </HStack>
          <HStack spacing="4" mb="4">
            <FormControl id="nombre">
              <FormLabel fontFamily="DM Sans" fontWeight="regular" ml="1">
                Empresa
              </FormLabel>
              <Input color="#18181B" value={nameCompany} padding="16px" fontFamily="DM Sans" isReadOnly borderRadius="12px" />
            </FormControl>

            <FormControl id="apellido">
              <FormLabel fontFamily="DM Sans" fontWeight="regular" ml="1">
                NIT
              </FormLabel>
              <Input fontFamily="DM Sans" padding="16px" color="#18181B" value={nitCompany} isReadOnly borderRadius="12px" />
            </FormControl>
          </HStack>
        </Box>
      </Box>
    </Sidebar>
  );
};

export default Settings;
