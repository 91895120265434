import { extendTheme } from '@chakra-ui/react';

const colors = {
  purple: {
    50: '#F5F0FF',
    100: '#E8D5FF',
    200: '#D0A9FF',
    300: '#4318FF',
    400: '#4318FF',
    500: '#2B3674',
    600: '#5459EA'
  },
  gray: {
    50: '#A3AED0',
    100: '#F5F7FB',
    150:'#DDDDDD',
    200:'#888888',
  },
  brand: {
    900: "#1a365d",
    800: "#153e75",
    700: "#2a69ac",
  },
};

const config = {
  initialColorMode: 'light',
  useSystemColorMode: false,
};

const fonts = {
  heading: `'DM Sans', sans-serif`,
  body: `'DM Sans', sans-serif`,
};

const fontWeights = {
  bold: 700,
  regular: 400,
  medium: 500,
};



const theme = extendTheme({
  config,
  colors,
  fonts,
  fontWeights,
});

export default theme;
